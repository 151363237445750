<template>
  <div class="cart">
    <v-card class="card-top ma-4">
      <custom-section class="section">
        <v-row ref="form">
          <v-col cols="12" md="8">
            <v-card class="card pa-5 mb-5" outlined elevation="1">
              <div class="mb-5">
                <heading-card
                  bold
                  color="dark-blue"
                  title="Customer Information"
                />
              </div>
              <v-row v-if="profile != null">
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.first_name"
                    label="First Name"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.last_name"
                    label="Last Name"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.email"
                    label="Email"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    :value="profile.phone"
                    label="Phone"
                    outlined
                    hide-details
                    disabled
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <div>
                <v-checkbox v-model="checkbox" dense hide-details>
                  <template v-slot:label>
                    <div class="checkbox--booking-else">
                      Please tick if you're making this booking for someone
                      else.
                    </div>
                  </template>
                </v-checkbox>
              </div>
              <!-- <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    value="Adi"
                    label="First Name"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    value="Kusma"
                    label="Last Name"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    value="adi_kusma@biznetnetworks.com"
                    label="Email"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    value="08123456789"
                    label="Phone"
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-col>
              </v-row> -->
            </v-card>
            <v-card class="card pa-5" outlined elevation="1">
              <div>
                <heading-card
                  bold
                  color="dark-blue"
                  class="mb-5"
                  :title="$t('page.office_care.branch_appointment')"
                />
              </div>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="branch"
                    ref="branch"
                    label="Branch"
                    item-text="name"
                    item-value="id"
                    v-model="payload.branch"
                    dense
                    outlined
                    hide-details=""
                    :rules="[
                      (v) => !!v || `${$t('branch')} ${$t('is_required')}`,
                    ]"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-menu
                    v-model="opendatepicker"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatted"
                        ref="booking_date"
                        label="Booking Date"
                        outlined
                        hide-details
                        append-icon="mdi-calendar-range"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          (v) =>
                            !!v || `${$t('book_date')} ${$t('is_required')}`,
                        ]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                    :locale="$i18n.locale"
                      v-model="payload.booking_date"
                      @change="opendatepicker = false"
                      :min="
                        new Date(
                          Date.now() - new Date().getTimezoneOffset() * 60000
                        )
                          .toISOString()
                          .substr(0, 10)
                      "
                      color="#00a4b3"
                      header-color="#00a4b3"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    ref="note"
                    v-model="payload.note"
                    outlined
                    rows="2"
                    hide-details
                    label="Order Comment"
                    placeholder="Type here..."
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="card pa-5 mb-5" outlined elevation="1">
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.order }"
                  bold
                  color="dark-blue"
                  title="Order Review"
                />
                <div @click="collapse.order = !collapse.order">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.order }"
                  />
                </div>
              </div>
              <template v-if="!collapse.order">
                <card-cart-item
                  v-if="serviceTest != null"
                  :name="serviceTest.name"
                  :price="`IDR ${formatPrice(
                    getInvoiceById(serviceTest.id).final_price
                  )}`"
                  :realPrice="`${
                    getInvoiceById(serviceTest.id).item_applied == 1
                      ? `IDR ${formatPrice(
                          getInvoiceById(serviceTest.id).price_total
                        )}`
                      : ''
                  }`"
                  hideClose
                />
              </template>
            </v-card>
            <v-card class="card pa-5 mb-5" outlined elevation="1">
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.voucher }"
                  bold
                  color="dark-blue"
                  title="Vouchers"
                />
                <div @click="collapse.voucher = !collapse.voucher">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.voucher }"
                  />
                </div>
              </div>
              <template v-if="!collapse.voucher && listDiscount != null">
                <template v-if="listDiscount.length > 0">
                  <voucher
                    v-for="(item, index) of listDiscount"
                    :key="index"
                    :discount="item.name"
                    :desc="`Up to IDR ${formatPrice(item.amount_max)}`"
                    :valid="`Valid until ${formatDate(item.end_date)}`"
                    class="voucher"
                    :active="checkVoucherActive(item.code)"
                    @toggleVoucher="toggleVoucher(item.code)"
                  />
                </template>
              </template>
              <p class="mb-0 count-item" v-else>Voucher not available!</p>
            </v-card>
            <v-card class="card pa-5" outlined elevation="1">
              <div class="d-flex justify-space-between align-content-start">
                <heading-card
                  :class="{ 'mb-4': !collapse.payment }"
                  bold
                  color="dark-blue"
                  title="Billing Summary"
                />
                <div @click="collapse.payment = !collapse.payment">
                  <icons
                    icon="icon-arrow-collapse"
                    :class="{ 'collapse--active': collapse.payment }"
                  />
                </div>
              </div>
              <template v-if="!collapse.payment">
                <div class="billing-box">
                  <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      text="Subtotal"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`IDR ${formatPrice(this.subTotal)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                  <div class="d-flex justify-space-between mb-3">
                    <heading-price
                      text="Voucher"
                      position="left"
                      color="gray"
                    />
                    <heading-price
                      :text="`- IDR ${formatPrice(this.totalDiscount)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                  <div class="d-flex justify-space-between mb-3">
                    <heading-price text="Tax" position="left" color="gray" />
                    <heading-price
                      :text="`IDR ${formatPrice(this.tax)}`"
                      position="right"
                      color="gray"
                      bold
                    />
                  </div>
                </div>
              </template>
              <div class="d-flex justify-space-between mt-3 mb-2">
                <heading-price
                  extraBold
                  text="Grand Total"
                  position="left"
                  color="main"
                />
                <heading-price
                  :text="`IDR ${formatPrice(this.totalPayment)}`"
                  position="right"
                  color="main"
                  extraBold
                />
              </div>
              <div>
                <v-checkbox
                  ref="privacy"
                  v-model="payload.privacy"
                  dense
                  hide-details
                  class="mb-3"
                  :rules="[(v) => !!v || `* please check this filed`]"
                >
                  <template v-slot:label>
                    <div class="checkbox--booking-else">
                      Please check to acknowledge our
                      <a href="#" target="blank">Privacy & Terms Policy</a>
                    </div>
                  </template>
                </v-checkbox>
              </div>

              <c-button
                bold
                shadow
                rounded
                fluid
                @click="payment"
                type="submit"
                :disabled="!show"
              >
                Pay IDR {{ formatPrice(this.totalPayment) }}</c-button
              >
            </v-card>
          </v-col>
        </v-row>
      </custom-section>
    </v-card>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import HeadingCard from "@/components/Heading/HeadingCard.vue";
import Icons from "@/components/elements/Icons.vue";
import CardCartItem from "@/components/v2/card/card_cart_item.vue";
import HeadingPrice from "@/components/Heading/HeadingPrice.vue";
import CButton from "@/components/v2/button/index.vue";
import Voucher from "@/components/v2/voucher/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import moment from "moment";
export default {
  components: {
    CustomSection,
    HeadingCard,
    Icons,
    CardCartItem,
    HeadingPrice,
    CButton,
    Voucher,
  },
  data: () => ({
    branch: [],
    profile: null,
    mypackage: null,
    checkbox: false,
    payload: {
      booking_date: "",
      branch: "",
      note: "",
      privacy: false,
    },
    collapse: {
      order: false,
      payment: false,
      voucher: false,
    },
    show: true,
    listDiscount: null,
    activeVoucher: [],
    invoice: null,
    subTotal: 0,
    totalPayment: 0,
    totalDiscount: 0,
    tax: 0,
    opendatepicker: false,
    formHasErrors: false,
    errorMessages: "",
    midtransStatus: 406,
    serviceTest: null,
  }),
  computed: {
    dateFormatted() {
      return this.formatDate(this.payload.booking_date);
    },
    form() {
      return {
        booking_date: this.payload.booking_date,
        branch: this.payload.branch,
        privacy: this.payload.privacy,
      };
    },
  },
  created() {
    this.payload.booking_date = moment().format("YYYY-MM-DD");
    this.getProfile();
    this.getTest();
  },
  methods: {
    async getTest() {
      try {
        const resTest = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get?product_id=${this.$route.params.id}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resTest.statusCode === 200) {
          const getIndexTest = resTest.results.findIndex(
            (dataTest) => dataTest.id == this.$route.params.branch
          );
          this.branch = resTest.results;
          this.payload.branch = parseInt(this.$route.params.branch);
          this.serviceTest = {
            ...resTest.results[getIndexTest].product,
            id: this.$route.params.id,
          };
          this.getDiscount();
          this.calculatePayment();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getProfile() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}profile`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.profile = {
          email: resp.person.personal_info.contacts.email,
          first_name: resp.person.first_name,
          last_name: resp.person.last_name,
          phone: resp.person.personal_info.contacts.mobile_phone_number,
        };
      } catch (error) {
        console.log(error);
      }
    },
    getInvoiceById(id) {
      if (typeof this.invoice !== "undefined" && this.invoice != null) {
        if (this.invoice.length > 0) {
          const index = this.invoice.findIndex((item) => item.id == id);
          if (index >= 0) {
            return this.invoice[index];
          }
        }
      }
      return { final_price: 0, item_applied: 0, price_total: 0 };
    },
    formatPrice(price) {
      return `${Intl.NumberFormat().format(price)}.00`;
    },

    async getDiscount() {
      const payloadDiscount = {
        ids: [this.serviceTest.id],
        plant_ids: [this.payload.branch],
        type: "product",
      };
      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}discount/get`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          payloadDiscount
        );
        if (resp.statusCode == 200 && resp.results.length > 0) {
          this.listDiscount = resp.results;
        }
      } catch (error) {
        console.log(error);
      }
    },
    toggleVoucher(item) {
      if (!this.checkVoucherActive(item)) {
        this.activeVoucher.push(item);
      } else {
        this.activeVoucher.splice(this.activeVoucher.indexOf(item), 1);
      }
      this.calculatePayment();
    },
    // check voucher active
    checkVoucherActive(voucher) {
      return this.activeVoucher.some((item) => item == voucher);
    },
    // calculate payment
    async calculatePayment() {
      if (this.$route.params.id) {
        const payload = {
          type: "product",
          plant_id: this.payload.branch,
          items: [{ id: this.serviceTest.id, qty: 1 }],
          vouchers: this.activeVoucher,
        };
        try {
          const resp = await API.post(
            `${process.env.VUE_APP_API_TRUST}discount/apply`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            payload
          );

          if (resp.statusCode == 200) {
            this.invoice = resp.results;
            this.totalPayment = 0;
            this.totalDiscount = 0;
            this.subTotal = 0;
            this.invoice.forEach((item) => {
              this.totalPayment = this.totalPayment + item.final_price;
              this.totalDiscount = this.totalDiscount + item.discount_amount;
              this.subTotal = this.subTotal + item.price_total;
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // reset when 0
        this.invoice = null;
        this.totalPayment = 0;
        this.totalDiscount = 0;
        this.subTotal = 0;
      }
    },
    async payment() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors && this.invoice.length > 0) {
        let discount_amount = "";
        let voucher = "";
        this.show = false;
        if (this.invoice[0].voucher != null) {
          discount_amount = this.invoice[0].discount_amount;
          voucher = this.invoice[0].voucher;
        } else {
          discount_amount = null;
          voucher = null;
        }
        const dataReady = [
          {
            booking_date: this.payload.booking_date,
            amount: this.totalPayment,
            plant_id: this.payload.branch,
            description: this.payload.note,
            notes: this.payload.note,
            product_order_detail: [
              {
                product_id: this.serviceTest.id,
                qty: 1,
                voucher: voucher,
                discount_amount: discount_amount,
              },
            ],
          },
        ];
        // submit reagis order
        try {
          const order = await API.post(
            `${process.env.VUE_APP_API_TRUST}registration/book`,
            {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth.getToken()}`,
            },
            dataReady
          );
          if (order.statusCode === 200) {
            if (
              order.results.length > 0 &&
              order.results[0].midtrans_token != null
            ) {
              if (this.$store.state.midtransIsLoaded) {
                const vuethis = this;
                window.snap.pay(order.results[0].midtrans_token, {
                  onPending: function (result) {
                    vuethis.midtransStatus = result.statusCode;
                    API.get(
                      `${process.env.VUE_APP_API_TRUST}registration/status-transaction?invoice_number=${result.order_id}`,
                      {
                        Authorization: `Bearer ${Auth.getToken()}`,
                      }
                    ).then((res) => {
                      if (res.status_code == 200) {
                        vuethis.midtransStatus = res.status_code;
                        vuethis.$router.push(
                          `/${this.$i18n.locale}/payment-success`
                        );
                      } else {
                        vuethis
                          .$swal({
                            text: vuethis.$t("the_order_has_been_saved"),
                            showCancelButton: false,
                            confirmButtonColor: "#1A428A",
                            confirmButtonText: "Lanjut",
                          })
                          .then(() => {
                            vuethis.$router.push(`/patient/profile?tab=order`);
                          });
                      }
                    });
                  },
                  onClose: function () {
                    vuethis
                      .$swal({
                        text: vuethis.$t("the_order_has_been_saved"),
                        showCancelButton: false,
                        confirmButtonColor: "#1A428A",
                        confirmButtonText: "Lanjut",
                      })
                      .then(() => {
                        vuethis.$router.push(`/patient/profile?tab=order`);
                      });
                  },
                  onError: function (result) {
                    vuethis.midtransStatus = result.statusCode;
                    vuethis.$swal({
                      icon: "error",
                      text: "Error payment!",
                    });
                  },
                });
              }
            } else {
              this.midtransStatus = 0;
              this.$router.push(`/${this.$i18n.locale}/payment-success`);
            }
          }
        } catch (error) {
          this.$swal({
            icon: "error",
            text: this.$t("error_booking"),
          });
        }
      }
    },
    async resetCart() {
      // reset cart
      const cart = this.$store.state.cart.map((res) => {
        let data = res;
        data.product_id = res.product.id;
        data.plant_id = res.plant.id;
        data.qty = 0;
        return data;
      });
      try {
        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}cart/store`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          cart
        );

        if (resp.statusCode == 200) {
          this.getCart();
          if (this.midtransStatus == 200) {
            this.$router.push(`/${this.$i18n.locale}/payment-success`);
          } else if (this.midtransStatus == 0) {
            this.$router.push(`/dashboard/use-success`);
          } else {
            this.$router.push(`/patient/profile?tab=order`);
          }
        } else if (resp.statusCode == 400) {
          Object.keys(resp.errors).forEach((element) => {
            this.toast(
              "error",
              typeof resp.errors[element] == "object"
                ? resp.errors[element][0]
                : resp.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.cart {
  .count-item {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .billing-box {
    border-bottom: 1px solid #e0e0e0;
  }
  .checkbox {
    &--booking-else {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: gray;
      text-transform: none;
    }
  }
  .voucher {
    margin-bottom: 20px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .collapse--active {
    transform: rotate(180deg);
  }
  a {
    text-decoration: none;
  }
}
</style>
